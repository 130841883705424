.modal-root {
  position: relative;
  transition: opacity 0.3s linear;
  will-change: opacity;
  z-index: 9999;
}

.modal-root__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($base-overlay-background, 0.9);
}

.modal-root__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: space-around;
  z-index: 9999;
  pointer-events: none;
  user-select: none;
}

.modal-root__modal {
  pointer-events: auto;
  display: flex;
  z-index: 9999;
  max-height: 100%;
  overflow-y: hidden;
}

.video-modal,
.audio-modal {
  max-width: 100vw;
  max-height: 100vh;
  position: relative;
}

.video-modal {
  .video-player video {
    height: auto;
  }
}

.media-modal {
  width: 100%;
  height: 100%;
  position: relative;

  .audio-player.detailed,
  .extended-video-player {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .audio-player {
    max-width: 800px;
    max-height: 600px;
  }

  .extended-video-player {
    width: 100%;
    height: 100%;

    video {
      max-width: $media-modal-media-max-width;
      max-height: $media-modal-media-max-height;
    }
  }
}

.media-modal__closer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.media-modal__navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: opacity 0.3s linear;
  will-change: opacity;

  * {
    pointer-events: auto;
  }

  &.media-modal__navigation--hidden {
    opacity: 0;

    * {
      pointer-events: none;
    }
  }
}

.media-modal__nav {
  background: rgba($base-overlay-background, 0.5);
  box-sizing: border-box;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 24px;
  height: 20vmax;
  margin: auto 0;
  padding: 30px 15px;
  position: absolute;
  top: 0;
  bottom: 0;

  @media screen and (max-width: 600px) { padding: 30px 2px; }

  .svg-icon {
    width: 24px;
    height: 24px;
  }
}

.media-modal__nav--left {
  left: 0;
}

.media-modal__nav--right {
  right: 0;
}

.media-modal__pagination {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 20px;
  pointer-events: none;
}

.media-modal__meta {
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  pointer-events: none;

  &--shifted {
    bottom: 62px;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}

.media-modal__page-dot {
  display: inline-block;
}

.media-modal__button {
  background-color: #fff;
  height: 12px;
  width: 12px;
  border-radius: 6px;
  margin: 10px;
  padding: 0;
  border: 0;
  font-size: 0;
}

.media-modal__button--active {
  background-color: var(--highlight-text-color);
}

.media-modal__close {
  position: absolute;
  right: 8px;
  top: 8px;
  height: 48px;
  width: 48px;
  z-index: 100;
  color: #fff;

  .svg-icon {
    height: 48px;
    width: 48px;
  }
}

.onboarding-modal,
.error-modal,
.embed-modal,
.login-modal {
  background: var(--background-color);
  color: var(--primary-text-color);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.embed-modal {
  max-width: 420px;
}

.error-modal__body {
  height: 80vh;
  width: 80vw;
  max-width: 520px;
  max-height: 420px;
  position: relative;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    user-select: text;
  }
}

.error-modal__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.onboarding-modal__paginator,
.error-modal__footer {
  flex: 0 0 auto;
  background: var(--background-color);
  display: flex;
  padding: 25px;

  & > div {
    min-width: 33px;
  }

  .onboarding-modal__nav,
  .error-modal__nav {
    color: var(--highlight-text-color);
    border: 0;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px;
    line-height: inherit;
    height: auto;
    margin: -10px;
    border-radius: 4px;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      color: var(--primary-text-color--faint);
      background-color: var(--background-color);
    }

    &.onboarding-modal__done,
    &.onboarding-modal__next {
      color: var(--primary-text-color);

      &:hover,
      &:focus,
      &:active {
        color: var(--primary-text-color);
      }
    }
  }
}

.error-modal__footer {
  justify-content: center;
}

.display-case {
  text-align: center;
  font-size: 15px;
  margin-bottom: 15px;

  &__label {
    font-weight: 500;
    color: var(--primary-text-color);
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }

  &__case {
    background: var(--brand-color--med);
    color: var(--primary-text-color--faint);
    font-weight: 500;
    padding: 10px;
    border-radius: 4px;
  }
}

.onboard-sliders {
  display: inline-block;
  max-width: 30px;
  max-height: auto;
  margin-left: 10px;
}

.boost-modal,
.confirmation-modal,
.report-modal,
.actions-modal,
.mute-modal,
.reactions-modal,
.reblogs-modal,
.mentions-modal {
  position: relative;
  flex-direction: column;
  overflow: hidden;
  width: 480px;
  max-width: 90vw;
  border-radius: 10px;
  border: 1px solid var(--background-color);
  color: var(--primary-text-color--faint);
  background: var(--foreground-color);

  .status__display-name {
    display: block;
    max-width: 100%;
    padding-right: 25px;
  }

  .status__avatar {
    height: 28px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 48px;
  }

  .status__content__spoiler-link {
    color: var(--primary-text-color);
  }
}

.actions-modal {
  .status {
    background: var(--background-color);
    border-bottom-color: var(--background-color);
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dropdown-menu__separator {
    display: block;
    margin: 10px;
    height: 1px;
    background: var(--background-color);
  }
}

.reactions-modal,
.reblogs-modal,
.mentions-modal {
  height: 80vh;
  max-height: 650px;

  .slist {
    overflow: auto;
  }
}

.boost-modal__container {
  overflow-x: scroll;
  padding: 10px;

  .status {
    user-select: text;
    border-bottom: 0;
  }
}

.boost-modal__action-bar,
.confirmation-modal__action-bar,
.mute-modal__action-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--background-color);
  padding: 10px;

  & > div {
    flex: 1 1 auto;
    text-align: right;
    color: var(--highlight-text-color);
    padding-right: 10px;
  }

  .button {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .submit__favicon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        max-height: 100%;
      }
    }
  }
}

.boost-modal__status-header {
  font-size: 15px;
}

.boost-modal__status-time {
  float: right;
  font-size: 14px;
}

.mute-modal {
  line-height: 24px;
}

.mute-modal .react-toggle {
  vertical-align: middle;
}

.report-modal {
  width: 90vw;
  max-width: 700px;
}

.report-modal__container {
  display: flex;
  border-top: 1px solid var(--background-color);

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
    overflow-y: auto;
  }
}

.report-modal__statuses,
.report-modal__comment {
  box-sizing: border-box;
  width: 50%;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.report-modal__statuses {
  flex: 1 1 auto;
  min-height: 20vh;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;

  .status__content a {
    color: var(--highlight-text-color);
  }

  .status__content,
  .status__content p {
    color: var(--primary-text-color--faint);
  }

  @media screen and (max-width: 480px) {
    max-height: 10vh;
  }
}

.report-modal__comment {
  padding: 20px;
  border-right: 1px solid var(--background-color);
  max-width: 320px;

  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .setting-text {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    color: var(--primary-text-color);
    background: var(--background-color);
    padding: 10px;
    font-family: inherit;
    font-size: 14px;
    resize: vertical;
    outline: 0;
    border: 1px solid var(--background-color);
    border-radius: 4px;
    margin-bottom: 20px;

    &:focus {
      border: 1px solid var(--background-color);
    }
  }

  .setting-toggle {
    margin-top: 20px;
    margin-bottom: 24px;

    &__label {
      color: var(--primary-text-color);
      font-size: 14px;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 10px;
    max-width: 100%;
    order: 2;

    .setting-toggle {
      margin-bottom: 4px;
    }
  }
}

.actions-modal {
  max-height: calc(100% - 16px);
  width: calc(100% - 16px);
  max-width: 500px;
  margin: auto 0 8px;

  .status {
    overflow-y: auto;
    max-height: 300px;
  }

  .actions-modal__item-label { font-weight: 500; }

  ul {
    overflow-y: auto;
    flex-shrink: 0;
    max-height: calc(100vh - 147px);

    // NOTE - not sure what this is yet, leaving alone for now until I find out.
    &.with-status { max-height: calc(80vh - 75px); }

    li:empty { margin: 0; }

    li:not(:empty) {
      &:first-of-type { margin: 10px 0 0; }

      a {
        display: flex;
        align-items: center;
        padding: 13px 10px 12px;
        color: var(--primary-text-color);
        text-decoration: none;
        opacity: 0.6;

        &,
        button {
          transition: none;
        }

        &.destructive {
          color: var(--warning-color--hicontrast);
          opacity: 1;
        }

        &.active,
        &:hover,
        &:focus {
          opacity: 1;

          &,
          button {
            background: var(--background-color);
            color: var(--highlight-text-color);
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
          }
        }

        .svg-icon:first-child {
          height: 20px;
          width: 20px;
          margin-right: 10px;

          svg {
            stroke-width: 1.5;

            &.feather {
              // Feather icons are a little larger
              transform: scale(0.9);
            }
          }
        }
      }
    }
  }

  &__close-button {
    width: calc(100% - 16px);
    margin: 8px;
    background: var(--accent-color);
    font-size: 15px;
    font-weight: 600;

    &:active,
    &:focus,
    &:hover {
      background: var(--accent-color--bright);
    }
  }
}

.confirmation-modal__action-bar,
.mute-modal__action-bar {
  .confirmation-modal__secondary-button,
  .confirmation-modal__cancel-button,
  .mute-modal__cancel-button {
    background-color: transparent;
    color: var(--highlight-text-color);
    font-size: 14px;
    font-weight: 500;

    &:hover,
    &:focus,
    &:active {
      color: var(--primary-text-color--faint);
    }
  }

  .confirmation-modal__secondary-button {
    flex-shrink: 1;
  }
}

.confirmation-modal__container,
.mute-modal__container,
.report-modal__target {
  padding: 30px;
  font-size: 16px;
  text-align: center;

  strong {
    font-weight: 500;

    @each $lang in $cjk-langs {
      &:lang(#{$lang}) {
        font-weight: 700;
      }
    }
  }

  .account {
    text-align: left;
    background-color: var(--background-color);
    border-radius: 4px;
    margin-bottom: 16px;
  }
}

.confirmation-modal__checkbox {
  padding: 0 30px;

  .simple_form {
    margin-top: -14px;
  }
}

.report-modal__target {
  padding: 20px;

  .media-modal__close {
    top: 19px;
    right: 15px;
    height: 20px;
    width: 20px;

    .svg-icon {
      color: var(--primary-text-color);
      height: 20px;
      width: 20px;
    }
  }
}

.modal-layout {
  background: var(--brand-color--med) url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234.80078 31.757813" width="234.80078" height="31.757812"><path d="M19.599609 0c-1.05 0-2.10039.375-2.90039 1.125L0 16.925781v14.832031h234.80078V17.025391l-16.5-15.900391c-1.6-1.5-4.20078-1.5-5.80078 0l-13.80078 13.099609c-1.6 1.5-4.19883 1.5-5.79883 0L179.09961 1.125c-1.6-1.5-4.19883-1.5-5.79883 0L159.5 14.224609c-1.6 1.5-4.20078 1.5-5.80078 0L139.90039 1.125c-1.6-1.5-4.20078-1.5-5.80078 0l-13.79883 13.099609c-1.6 1.5-4.20078 1.5-5.80078 0L100.69922 1.125c-1.600001-1.5-4.198829-1.5-5.798829 0l-13.59961 13.099609c-1.6 1.5-4.200781 1.5-5.800781 0L61.699219 1.125c-1.6-1.5-4.198828-1.5-5.798828 0L42.099609 14.224609c-1.6 1.5-4.198828 1.5-5.798828 0L22.5 1.125C21.7.375 20.649609 0 19.599609 0z" fill="#{hex-color(var(--background-color))}"/></svg>') repeat-x bottom fixed;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
}

@media screen and (max-width: 600px) {
  .account-header {
    margin-top: 0;
  }
}

.compose-modal,
.reply-mentions-modal {
  overflow: hidden;
  background-color: var(--background-color);
  border-radius: 6px;
  flex-direction: column;
  width: 600px;
  margin: 10px 0;

  &__header {
    display: flex;
    position: relative;
    padding: 10px 0;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    border-bottom: 1px solid hsla(var(--primary-text-color_hsl), 0.2);
    align-items: center;
    justify-content: center;

    &__title {
      display: block;
      width: 80%;
      margin: 0 auto;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: var(--primary-text-color);
      text-align: center;
    }
  }

  @media screen and (max-width: 895px) {
    margin: 0;
    border-radius: 0;
    height: 100vh;
    width: 100vw;
  }
}

.compose-modal {
  &__close {
    position: absolute;
    right: 10px;
    right: max(10px, env(safe-area-inset-right));
    color: var(--primary-text-color--faint);

    .svg-icon {
      width: 24px;
      height: 24px;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 10px;
    padding-left: max(10px, env(safe-area-inset-left));
    padding-right: max(10px, env(safe-area-inset-right));
    overflow-y: hidden;

    &--scroll {
      display: block;
      overflow-y: auto;
    }

    .timeline-compose-block {
      background: transparent !important;
      width: 100%;
      padding: 0;
      margin-bottom: 0;

      .compose-form {
        max-height: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 !important;

        .compose-form__autosuggest-wrapper .autosuggest-textarea__textarea {
          max-height: 160px !important;
        }

        .compose-form__autosuggest-wrapper::before {
          content: none !important;
        }
      }
    }

    .compose-form .compose-form__buttons-wrapper {
      padding: 10px 0;
    }
  }
}

.reply-mentions-modal {
  &__back {
    position: absolute;
    left: 10px;
    left: max(10px, env(safe-area-inset-right));
    color: var(--primary-text-color--faint);

    .svg-icon {
      width: 24px;
      height: 24px;
    }
  }

  &__accounts {
    display: block;
    flex-direction: row;
    flex: 1;
    overflow-y: auto;
    min-height: 300px;
  }
}

.unauthorized-modal {
  width: 440px !important;

  &__content {
    display: flex;
    width: 100%;
    height: 150px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .unauthorized-modal-content {
    &__text {
      display: block;
      margin-bottom: 18px;
      color: var(--primary-text-color);
      font-size: 14px;
    }

    &__button {
      width: 200px;
    }
  }

  &__footer {
    border-top: 1px solid #666;
    padding: 10px;
    font-size: 14px;
    text-align: center;
    color: var(--primary-text-color--faint);

    a {
      color: var(--brand-color) !important;
    }
  }

  @media screen and (max-width: 895px) {
    height: 270px !important;
    width: 330px !important;
  }
}

.remote-interaction-modal {
  &__content {
    display: flex;
    flex-direction: column;
    // align-items: center;
    row-gap: 10px;
    padding: 10px;

    .unauthorized-modal-content__button {
      margin: 0 auto;
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .button {
      width: auto;
      margin: 0;
      text-transform: none;
      overflow: unset;
    }
  }

  &__divider {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 -10px;

    &::before,
    &::after {
      content: "";
      flex: 1;
      border-bottom: 1px solid hsla(var(--primary-text-color_hsl), 0.2);
    }
  }

  @media screen and (max-width: 895px) {
    margin: 0;
    border-radius: 6px;
    height: unset !important;
    width: 440px !important;
  }

  @media screen and (max-width: 480px) {
    width: 330px !important;
  }
}

.focal-point-modal {
  max-width: 80vw;
  max-height: 80vh;
  position: relative;
}

.embed-modal {
  max-width: 80vw;
  max-height: 80vh;

  h4 {
    padding: 30px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
  }

  .embed-modal__container {
    padding: 10px;

    .hint {
      margin-bottom: 15px;
    }

    .embed-modal__html {
      outline: 0;
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 0;
      border-radius: 5px;
      padding: 10px;
      font-family: var(--font-monospace), monospace;
      background: var(--brand-color--med);
      color: var(--primary-text-color);
      font-size: 14px;
      margin: 0;
      margin-bottom: 15px;

      &::-moz-focus-inner {
        border: 0;
      }

      &::-moz-focus-inner,
      &:focus,
      &:active {
        outline: 0 !important;
      }

      &:focus {
        background: var(--brand-color--faint);
      }

      @media screen and (max-width: 600px) {
        font-size: 16px;
      }
    }

    .embed-modal__iframe {
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      border: 0;

      iframe {
        width: 100%;
      }
    }
  }
}

.modal-container--preloader {
  background: var(--brand-color--med);
}

.column-inline-form {
  padding: 7px 15px;
  padding-right: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--brand-color--faint);

  label {
    flex: 1 1 auto;

    input {
      width: 100%;
      margin-bottom: 6px;

      &:focus {
        outline: 0;
      }
    }
  }

  .icon-button {
    flex: 0 0 auto;
    margin: 0 5px;
  }
}

.confirmation-modal p {
  margin-bottom: 20px;
  text-align: left;

  strong {
    font-weight: bold;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.confirmation-modal,
.mute-modal {
  &__header {
    display: flex;
    align-items: center;
    column-gap: 6px;
    padding: 20px;
    padding-bottom: 0;
    font-size: 22px;

    .svg-icon {
      height: 24px;
      width: 24px;
      color: var(--primary-text-color);
      opacity: 0.6;
    }
  }

  &__container {
    padding: 20px;
    text-align: left;
  }
}
